import { CronDate } from "./date.js";

/**
 * @typedef {Object} CronOptions - Cron scheduler options
 * @property {boolean} [paused] - Job is paused
 * @property {boolean} [kill] - Job is about to be killed or killed
 * @property {boolean} [catch] - Continue exection even if a unhandled error is thrown by triggered function
 * @property {number} [maxRuns] - Maximum nuber of executions
 * @property {number} [interval] - Minimum interval between executions, in seconds
 * @property {string | Date} [startAt] - When to start running
 * @property {string | Date} [stopAt] - When to stop running
 * @property {string} [timezone] - Time zone in Europe/Stockholm format
 * @property {boolean} [legacyMode] - Combine day-of-month and day-of-week using OR. Default is AND.
 * @property {?} [context] - Used to pass any object to scheduled function
 */

/**
 * Internal function that validates options, and sets defaults
 * @private
 * 
 * @param {CronOptions} options 
 * @returns {CronOptions}
 */
function CronOptions(options) {
	
	// If no options are passed, create empty object
	if (options === void 0) {
		options = {};
	}
	
	// Keep options, or set defaults
	options.legacyMode = (options.legacyMode === void 0) ? false : options.legacyMode;
	options.paused = (options.paused === void 0) ? false : options.paused;
	options.maxRuns = (options.maxRuns === void 0) ? Infinity : options.maxRuns;
	options.catch = (options.catch === void 0) ? false : options.catch;
	options.interval = (options.interval === void 0) ? 0 : parseInt(options.interval, 10);
	options.kill = false;
	
	// startAt is set, validate it
	if( options.startAt ) {
		options.startAt = new CronDate(options.startAt, options.timezone);
	} 
	if( options.stopAt ) {
		options.stopAt = new CronDate(options.stopAt, options.timezone);
	}

	// Validate interval
	if (options.interval !== null) {
		if (isNaN(options.interval)) {
			throw new Error("CronOptions: Supplied value for interval is not a number");
		} else if (options.interval < 0) {
			throw new Error("CronOptions: Supplied value for interval can not be negative");
		}
	}

	return options;

}

export { CronOptions };
// denoCacheMetadata={"headers":{"x-frame-options":"DENY","referrer-policy":"strict-origin-when-cross-origin","cross-origin-opener-policy":"same-origin","vary":"Accept-Encoding, Origin","via":"http/2 edgeproxy-h","etag":"W/\"ac6f4b939f23173adb9c563d82b24132\"","cross-origin-embedder-policy":"same-origin","x-amz-replication-status":"COMPLETED","cache-control":"public, max-age=31536000, immutable","content-type":"application/javascript","strict-transport-security":"max-age=63072000; includeSubDomains; preload","access-control-allow-origin":"*","age":"14160651","last-modified":"Fri, 16 Sep 2022 08:42:55 GMT","server-timing":"fetchSource;dur=10","x-amz-cf-id":"yDoDFAeuYJtwHbebcMAhRWF6FVp6Wfy9Hu9-7L2IeaoftPVI7f3AOQ==","x-amz-cf-pop":"IAD61-P1","x-amz-version-id":"c.MnO5kLQ45egpQDrqZniQFGabgBX333","server":"deno/gcp-us-east4","x-cache":"Hit from cloudfront","date":"Tue, 04 Jun 2024 18:27:31 GMT","cross-origin-resource-policy":"same-origin","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","x-content-type-options":"nosniff"},"url":"https://deno.land/x/croner@4.4.1/src/options.js","time":1731686301}